html, body{
    width: 100%;
    height: 100%;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

*{
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
}

a:hover {
    color: #e95354!important;
}

a:link,
a:visited,
a:active{
    color: black;
    text-decoration: none;
}


/* ----------- Layout ----------- */

.top-nav{
    width: 100%;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    top:0;
}

.bottom-nav{
    width: 100%;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    bottom:0;
}

.credit{
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.text{
    width: 55%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}




/* ----------- Type ----------- */
h1{
    font-family: essonnes-display, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.75vw;
}

h2{
    font-family: essonnes-display, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.75vw;
    text-align: center;
}

h3{
    font-family: essonnes-display, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.75vw;
    color: #aaa;
}

h3 a:link,
h3 a:visited,
h3 a:active{
    color: grey;
    text-decoration: none;
}


/* ----------- Flickity ----------- */

* { box-sizing: border-box; }

body { font-family: sans-serif; }

.carousel {
  width: 100%;
  max-height: 75vh;
  overflow: hidden;
  position: absolute!important;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-cell {
  width: 28%;
  margin: 0px 25px;
  counter-increment: gallery-cell;
  opacity: 0.1;
  transition: opacity 1s;
  height: 75vh;
}

.carousel-cell.is-selected {
    opacity: 1;
    transition: opacity 1s;
  }

.carousel-cell img{
    width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

}


/* no circle */
.flickity-button {
    background: transparent !important;
  }
  /* big previous & next buttons */
  .flickity-prev-next-button {
    width: 50px!important;
    height: 50px!important;
  }
  /* icon color */
  .flickity-button-icon {
    fill:black!important;
  }
  /* hide disabled button */
  .flickity-button:disabled {
    display: none!important;
  }

  .flickity-page-dots{
      visibility: hidden!important;
  }


/* ----------- Mobile ----------- */

@media all and (min-width: 0px) and (max-width: 768px){

    .carousel-cell {
        width: 75%;
        margin: 0px 10px;
        counter-increment: gallery-cell;
        opacity: 0.1;
        transition: opacity 1s;
      }   

      .flickity-prev-next-button{
          visibility: hidden!important;
      }

      .text{
        width: 75%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        }

      /* ----------- Type ----------- */
      h1{
        font-family: essonnes-display, serif;
        font-weight: 400;
        font-style: normal;
        font-size: 5.5vw;
      }
    
      h2{
          font-family: essonnes-display, serif;
          font-weight: 400;
          font-style: normal;
          font-size: 5.5vw;
          text-align: center;
      }
      
      h3{
          font-family: essonnes-display, serif;
          font-weight: 400;
          font-style: normal;
          font-size: 5.5vw;
          color: #aaa;
      }

}


